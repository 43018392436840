/**
 * Niche/specialty function to locate a deeply nested object by the
 * presence of a specific key/value combo.
 *
 * ```js
 * const a = {
 *   nested: {
 *     thing: {
 *       id: 1,
 *     },
 *   },
 * };
 *
 * findByKey(a, "id", 1) // returns `thing` obj
 * ```
 */
export default function findByKey(obj, key, value) {
  for (const k of [...Object.keys(obj), ...Object.getOwnPropertySymbols(obj)]) {
    const v = obj[k];
    if (v && typeof v === "object") {
      const r = findByKey(v, key, value);
      if (r) return r;
    }
    if (k !== key) continue;
    if (value === undefined || v === value) return obj;
  }

  return null;
}
