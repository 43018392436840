import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import router from "@/__main__/router.mjs";
import { GAME_NAME_MAP, HUB_NAME_MAP } from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import Ripple from "@/shared/Ripple.jsx";
import { findGameSymbol } from "@/util/game-route.mjs";

type HubNoContentProps = {
  game?: GameSymbol;
  page?: Translation | symbol;
  href?: string;
};

const Container = styled("div")`
  min-height: calc(100vh - var(--sp-1) * 160);
  width: inherit;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: var(--sp-4);

  .content-text {
    color: var(--shade2);
  }
  .content-game {
    color: var(--shade0);
  }
`;

const HubNoContent = ({
  game = findGameSymbol(),
  page = router.route.hubSymbol,
  href = typeof page === "symbol" ? `/${HUB_NAME_MAP[page]}` : "",
}: HubNoContentProps) => {
  const { t } = useTranslation();
  const pageTrans = Array.isArray(page) ? t(...page) : "this";

  const gameTrans = t(...GAME_NAME_MAP[game]);
  const captionTrans = t(
    "common:noContent.body",
    `Blitz doesn't have {{page}} for {{game}} yet`,
    {
      page: pageTrans,
      game: gameTrans,
    },
  );

  const [start, highlight, end] = splitUp(captionTrans, gameTrans);
  const actionTrans = t(
    "common:noContent.action",
    "Explore {{page}} for other games",
    {
      page: pageTrans,
    },
  );

  return (
    <Container>
      <div className="content-text">
        {start}
        <span className="content-game">{highlight}</span>
        {end}
      </div>
      <Button href={href} After={<Ripple />}>
        {actionTrans}
      </Button>
    </Container>
  );
};

function splitUp(str: string, delimiter: string): string[] {
  const arr = str.split(delimiter);
  arr.splice(1, 0, delimiter);
  return arr;
}

export default memo(HubNoContent);
