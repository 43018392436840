import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobileMedium, tablet } from "clutch/src/Style/style.mjs";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import {
  OVERLAY_TAG_FALLBACK_COLOR,
  OVERLAY_TAGS,
} from "@/hub-overlays/constants.mjs";

export const CardContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--sp-8) var(--sp-4);
  ${tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${mobileMedium} {
    grid-template-columns: 1fr;
  }
  &.featured {
    grid-template-rows: auto;
    grid-template-areas:
      "highlight highlight side1 side2"
      "highlight highlight side3 side4";
    & > div:nth-child(1) {
      grid-area: highlight;
      .img-container {
        aspect-ratio: 1 / 0.88;
      }
      ${tablet} {
        .img-container {
          aspect-ratio: 6 / 3.868;
        }
      }
      .featured-menu {
        right: -25%;
        top: 77%;
        ${tablet} {
          right: 0;
        }
      }
    }
    & > div:nth-child(2) {
      grid-area: side1;
    }
    & > div:nth-child(3) {
      grid-area: side2;
    }
    & > div:nth-child(4) {
      grid-area: side3;
    }
    & > div:nth-child(5) {
      grid-area: side4;
      ${tablet} {
        display: none;
      }
    }
    ${tablet} {
      grid-template-areas:
        "highlight side1"
        "side2 side3";
    }
    ${mobileMedium} {
      grid-template-areas:
        "highlight"
        "side1"
        "side2"
        "side3"
        "side4";
    }
  }
`;

export const OverlayTag = ({ tag }) => {
  const { t } = useTranslation();
  return (
    <Tag
      as="li"
      className="tag"
      size="sm"
      color={OVERLAY_TAGS[tag]?.color ?? OVERLAY_TAG_FALLBACK_COLOR}
      text={t(OVERLAY_TAGS[tag]?.text)}
    />
  );
};

export const UnsupportedNotice = styled("div")`
  margin-top: var(--sp-4);
  padding: var(--sp-3);

  display: flex;
  align-items: center;
  gap: var(--sp-3);

  background-color: var(--shade10);
  border-radius: var(--br);
  font-size: var(--sp-3_5e);
  color: var(--shade1);
`;
