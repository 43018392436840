import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import router from "@/__main__/router.mjs";
import type { GameOverlay } from "@/hub-overlays/constants.mjs";
import { IS_OVERLAYS_OS } from "@/hub-overlays/constants.mjs";
import { OverlayTag } from "@/hub-overlays/Overlays.style.jsx";
import SettingsIcon from "@/inline-assets/overlay-settings.svg";
import { setOverlayEnabled } from "@/library/actions.mjs";
import Tilting from "@/shared/Tilting.jsx";
import { classNames } from "@/util/class-names.mjs";
import ClientOnly, { useClientOnly } from "@/util/ClientOnly.jsx";

const OverlayContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--br);
  }

  &.disabled {
    opacity: 0.5;
  }
  &.disabled,
  &.no-controls {
    pointer-events: none;
    .overlay-toggle,
    .settings-icon {
      visibility: hidden;
    }
  }

  &.no-toggle {
    .overlay-toggle,
    .settings-icon {
      visibility: hidden;
    }
  }

  display: flex;
  flex-flow: column;
  gap: var(--sp-2);

  ul.tags {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
  }

  .tilt-card {
    outline: var(--sp-0_5) solid transparent;
    position: relative;
    justify-content: end;
    align-items: start;
    display: flex;
    aspect-ratio: 6 / 3.868;
    border-radius: var(--br);
    cursor: pointer;
    overflow: hidden;

    &:hover {
      outline-color: var(--shade0-50);
    }

    svg {
      margin: var(--sp-1);
      width: var(--sp-6_5);
      height: var(--sp-6_5);
      padding: var(--sp-1);
      color: var(--shade1);
      background: var(--shade10-75);
    }
  }

  .description {
    color: var(--shade2);
  }
  .disclaimer {
    display: inline-block;
    color: var(--red);
    background: var(--red-15);
    padding: var(--sp-1) var(--sp-2);
    border-radius: var(--br-sm);
  }
`;

const OverlayContent = ({
  overlay,
  isFeatured,
  loading,
}: {
  overlay: GameOverlay;
  isFeatured: boolean;
  loading?: "lazy" | "eager" | undefined;
}) => {
  const { t } = useTranslation();

  const overlayImage =
    isFeatured && overlay.images.featured
      ? overlay.images.featured
      : overlay.images.small;

  const hasActiveKey = !overlay.noToggle;
  const toggleHandler = useCallback(() => {
    setOverlayEnabled(overlay, !overlay.isActive);
    if (overlay.onToggle) {
      overlay.onToggle(overlay, !overlay.isActive);
    }
  }, [overlay]);

  const disabled = overlay.disabled && overlay.disabled({});
  const noControls = !IS_OVERLAYS_OS;
  const title = t(...overlay.title);

  const isHydrated = useClientOnly();

  return (
    <OverlayContentWrapper
      draggable={false}
      {...classNames(
        disabled && "disabled",
        noControls && "no-controls",
        overlay.noToggle && "no-toggle",
      )}
    >
      <Tilting
        className="tilt-card with-active"
        href={`${router.route.currentPath}?${new URLSearchParams({
          select: overlay.id,
        })}`}
      >
        <img src={overlayImage} alt={title} loading={loading} />
        {overlay.features && <SettingsIcon />}
      </Tilting>
      <div className="flex justify-between align-center">
        <p className="type-subtitle1">{title}</p>
        {hasActiveKey ? (
          <ClientOnly>
            <ToggleSwitch
              className="overlay-toggle"
              value={isHydrated && overlay.isActive}
              onChange={toggleHandler}
              color="var(--turq)"
              disabled={!isHydrated}
            />
          </ClientOnly>
        ) : null}
      </div>
      {overlay.tags?.length > 0 && (
        <ul className="tags">
          {overlay.tags.map((tag) => (
            <OverlayTag key={tag} tag={tag} />
          ))}
        </ul>
      )}
      <div className="type-caption description">
        {t(...(overlay.brief || overlay.description))}
      </div>
      {overlay.isActive && overlay.conflictDisclaimer && (
        <div className="type-caption disclaimer">
          {t(...overlay.conflictDisclaimer)}
        </div>
      )}
    </OverlayContentWrapper>
  );
};

export default OverlayContent;
