import React from "react";

import gamesList from "@/app/games.mjs";
import OverlaysContent from "@/hub-overlays/OverlaysContent.jsx";
import { findGameSymbol, useGameSymbol } from "@/util/game-route.mjs";

function Overlays() {
  const game = useGameSymbol();

  // MISSING: notice for MacOS that overlays are unsupported!
  return <OverlaysContent key={String(game?.toString())} game={game} />;
}

const metaMap = {
  ...gamesList.reduce((obj, { gameSymbol, meta }) => {
    obj[gameSymbol] = meta?.overlays;
    return obj;
  }, {}),
  default: {
    title: [
      "overlays:meta.title",
      "Blitz Overlays and In Game Tools for Coaching",
    ],
    description: [
      "overlays:meta.description",
      "Blitz makes overlays and in game coaching experiences for players to improve. We support LoL, TFT, VALORANT, & Apex Legends.",
    ],
    subtitle: [
      "overlays:meta.subtitle",
      "Get all the information you need to dominate live with overlays powered by Blitz.",
    ],
  },
};

export function meta() {
  const gameSymbol = findGameSymbol();
  return metaMap[gameSymbol] ?? metaMap.default;
}

export default Overlays;
