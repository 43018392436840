import { readPath } from "@/__main__/get-data.mjs";
import type {
  OverlayFeatureSelect,
  OverlayFeatureToggle,
  OverlaySettingKey,
} from "@/hub-overlays/constants.mjs";

export function getOverlaySettingKey(
  key: OverlaySettingKey,
): OverlaySettingKey {
  let [readKey, readPath] = key;

  if (Array.isArray(readKey)) {
    [readKey, readPath] = readKey;
  }

  return [readKey, readPath];
}

export function getOverlaySettingPath(
  key: OverlaySettingKey,
): OverlaySettingKey {
  if (Array.isArray(key) && key.length === 1) return key;

  let [readKey, readPath] = key;

  if (Array.isArray(readKey)) {
    [readKey, readPath] = readKey;
  }

  return [readKey as string, readPath];
}

// Excludes the db entry key
export function getOverlaySettingShortPath(key: OverlaySettingKey): string[] {
  const [, path] = getOverlaySettingKey(key);
  // @ts-ignore -- why are we checking if this is an array? it's always a string
  return Array.isArray(path) ? path : [path];
}

export function readSetting(settings, key): boolean | string {
  return readPath(getOverlaySettingPath(key), settings);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultOpt = (opt: any) => {
  if (!opt.default) return undefined;
  if (typeof opt.default === "function") {
    return opt.default();
  }
  return opt.default;
};
export function readToggleSetting(
  settings,
  toggle: OverlayFeatureToggle,
  freeTrialDate?: Date,
): boolean {
  if (typeof toggle.disabled === "function") {
    if (freeTrialDate && new Date() <= freeTrialDate) {
      return Boolean(readSetting(settings, toggle.key));
    } else if (toggle.disabled()) {
      return defaultOpt(toggle) ?? false;
    }
  }
  const v = readSetting(settings, toggle.key);
  if (typeof v !== "boolean") return defaultOpt(toggle) ?? false;
  return toggle.inverse ? !v : v;
}

export function readSelectSetting(
  settings,
  select: OverlayFeatureSelect,
): string | boolean | number | null {
  const v = readSetting(settings, select.key);
  if (typeof v !== "string" && typeof v !== "boolean" && typeof v !== "number")
    return defaultOpt(select) || null;
  return v ?? select.options[0].value;
}

export function getOverlaySettingLocation<
  S extends string | string[],
  StateT extends object,
>(state: StateT, game: string, key: S): unknown;
export function getOverlaySettingLocation<
  S extends string,
  StateT extends object,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: StateT,
  game: string,
  key: S,
): unknown;
export function getOverlaySettingLocation<
  S extends string | string[],
  StateT extends object,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: StateT,
  game: string,
  key: S,
) {
  if (typeof key === "string") {
    const statePath = stdOverlayPath(game, key);
    return readPath(statePath, state);
  }

  return readPath(key, state);
}

function stdOverlayPath(game: string, key: string) {
  return ["settings", game, key] as const;
}
