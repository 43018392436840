import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

function FreeTrialButton({ freeTrialDate }) {
  const { t } = useTranslation();
  return (
    <Button
      as="span"
      bgColor="var(--primary)"
      bgColorHover="var(--primary)"
      textColor="var(--white)"
      style={{ cursor: "default" }}
    >
      {t("overlays:proButton", "Free until {{date}}", {
        date: freeTrialDate.toLocaleDateString(),
      })}
    </Button>
  );
}

export default FreeTrialButton;
